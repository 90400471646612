var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "quota__details" },
    [
      _vm._l(_vm.quota.child_quotas, function (item) {
        return _c("div", { key: item.id, staticClass: "quota__details-item" }, [
          _c("div", { staticClass: "quota__main mod-child" }, [
            _vm._v(
              " " + _vm._s(_vm.getSupplierTitle(item.suppliers_ids[0])) + " "
            ),
          ]),
          _c(
            "div",
            { staticClass: "quota__progressbar" },
            [
              _c("div", { staticClass: "quota__progressbar-info" }, [
                _c(
                  "div",
                  {
                    class: { overflow: item.count > item.trucks_allowed_count },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.getProgressBarParams(item).title) + " "
                    ),
                  ]
                ),
                _c("div", { staticClass: "info__value" }, [
                  _vm._v(
                    " " +
                      _vm._s(item.count) +
                      "/" +
                      _vm._s(item.trucks_allowed_count) +
                      " "
                  ),
                ]),
              ]),
              _c("el-progress", {
                attrs: {
                  "text-inside": true,
                  "stroke-width": _vm.progressWidth,
                  percentage: _vm.getProgressBarParams(item).percentage,
                  color: _vm.getProgressBarParams(item).color,
                },
              }),
            ],
            1
          ),
        ])
      }),
      _c("div", { staticClass: "quota__details-item" }, [
        _c("div", { staticClass: "quota__main mod-child" }, [
          _c("div", [
            _c("span", [_vm._v("Нераспределенные таймслоты: ")]),
            _c("span", { staticClass: "title__unimproved" }, [
              _vm._v(_vm._s(_vm.getUnimprovedQuota)),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "quota__progressbar" }),
      ]),
      !_vm.isMobile
        ? _c(
            "div",
            { staticClass: "quota__details-button" },
            [
              _c(
                "iq-button",
                {
                  attrs: { color: "main", size: "small" },
                  on: { onClick: _vm.goToTimeslots },
                },
                [_vm._v(" Показать ТС ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }